export const ENV = "dev";

export const API_HOST = "https://dev.api.letsplaymusicsite.com/v2";
export const API_NAME = "dev-api";
export const API_CONFIG = { endpoints: [{ name: API_NAME, endpoint: API_HOST }] };

export const COGNITO_CONFIG = {
  region: "us-east-1",
  userPoolId: "us-east-1_Agj9H4jYz",
  userPoolWebClientId: "pp84qb58mt9pt60rq9pdl24r1",
  authenticationFlowType: "USER_PASSWORD_AUTH"
};

export const AMPLITUDE_KEY = "cc831be3e2a87a25bda7322ea85ea71f";